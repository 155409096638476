import {
  ACTIVITY_TOKEN_ERROR_KEY,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  EXPIRED_COOKIE_ERROR_KEY,
  INVALID_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_CODE,
  INVALID_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_KEY,
  INVALID_COOKIE_ERROR_KEY,
  AUTOLOGIN_ERROR_CODE,
  INVALID_COOKIE_STATE_ERROR_CODE,
  INVALID_COOKIE_STATE_ERROR_KEY,
  INVALID_TOKEN_ERROR_CODE,
  INVALID_TOKEN_ERROR_KEY,
} from '@constants/errors'
import { useSite } from '@foundation/hooks/useSite'
import Axios, { Canceler } from 'axios'
import React, { Dispatch, PropsWithChildren, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AUTOLOGIN_LOGIN_ID_KEY, AUTOLOGIN_TOKEN_KEY, CommerceEnvironment } from '@constants/common'
import { SIGNIN } from '@constants/routes'
import { genericErrorSelector, sessionErrorSelector } from '@features/error/selector'
import { resetSessionError } from '@features/error/thunks/resetSessionError'
import { loginStatusSelector } from '@features/user/selector'
import { logout } from '@features/user/thunks/logout'
import { initUserFromStorage } from '@features/user/thunks/storage/initStateFromStorage'
import { updateUserFromStorage } from '@features/user/thunks/storage/updateStateFromStorage'
import { setCustomerSegment } from '@foundation/hooks/useCustomerSegment'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import { customerSegmentsEnabledSelector, isRememberMeEnabledSelector } from '@redux/selectors/site'
import Log from '@services/Log'
import { countryUtil } from '@utils/countryUtil'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import BaseLayout from './BaseLayout'
import { getServerLocale } from '@utils/locale'
import { useRequireFullAuth } from '@hooks/useRequireFullAuth/useRequireFullAuth'
import { isPartiallyAuthenticatedSelector } from '@features/context/selector'
import { useSearchParams } from 'next/navigation'
import { isAutoLoginSelector } from '@features/user/selector'

export const StoreAppContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [hasError, setHasError] = useState(false)
  const widgetName = StoreAppContainer.name
  const dispatch = useDispatch<Dispatch<any>>()
  const router = useRouter()
  const { mySite } = useSite()
  const country = getServerLocale(router.isLocaleDomain, router.locale?.toLowerCase())
  const { i18n } = useTranslation()
  const CancelToken = Axios.CancelToken
  const cancels: Canceler[] = []

  const customerSegmentEnabled = useSelector(customerSegmentsEnabledSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)
  const isAutologin = useSelector(isAutoLoginSelector)
  const searchParams = useSearchParams()
  const partiallyAuthenticatedRedirectParam = searchParams.get('redirect')

  const countryValues = CommerceEnvironment.localeLangMap
  const controlUrlCountry = countryValues.find(t => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })
  const loginStatus = useSelector(loginStatusSelector)

  const isResumingOrder = !!(router.query?.[AUTOLOGIN_LOGIN_ID_KEY] && router.query?.[AUTOLOGIN_TOKEN_KEY])

  const isPartiallyAuthenticated = useSelector(isPartiallyAuthenticatedSelector)
  const sessionError = useSelector(sessionErrorSelector, shallowEqual)
  const error: any = useSelector(genericErrorSelector)
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    ...(isRememberMeEnabled && { rememberMe: false, updateCookies: true }),
    storeId: mySite?.storeID,
  }
  const { handleNotAllowedWithPartialAuth } = useRequireFullAuth()

  useEffect(() => {
    if (
      (isPartiallyAuthenticated && !partiallyAuthenticatedRedirectParam) ||
      (AUTOLOGIN_ERROR_CODE === sessionError.errorCode && isAutologin)
    ) {
      handleNotAllowedWithPartialAuth({
        redirectUrl: router.asPath,
        hasErrorCode: true,
      })
    }
  }, [sessionError, isPartiallyAuthenticated])

  useEffect(() => {
    if (customerSegmentEnabled) {
      setCustomerSegment(mySite)
    }
  }, [loginStatus, customerSegmentEnabled])

  // todo this should be moved to a middleware handling error responses and their actions
  // todo errorKey and errorCode should be combinations not 'or' throughout handleAxiosErrors and here.
  useEffect(() => {
    if (
      !hasError &&
      (sessionError.errorKey === INVALID_COOKIE_ERROR_KEY ||
        sessionError.errorCode === EXPIRED_ACTIVITY_TOKEN_ERROR ||
        error.errorKey === EXPIRED_COOKIE_ERROR_KEY)
    ) {
      const payload = {
        ...payloadBase,
      }
      setHasError(true)
      dispatch(resetSessionError())
      dispatch(logout(payload))
    } else if (sessionError.errorKey === ACTIVITY_TOKEN_ERROR_KEY) {
      const payload = {
        ...payloadBase,
      }
      dispatch(resetSessionError())
      dispatch(logout(payload))
    }

    // todo merge the above with the below
    // todo action factory for these combinations
    if (
      !hasError &&
      ((sessionError.errorKey === INVALID_TOKEN_ERROR_KEY && sessionError.errorCode === INVALID_TOKEN_ERROR_CODE) ||
        (sessionError.errorKey === INVALID_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_KEY &&
          sessionError.errorCode === INVALID_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_CODE) ||
        (sessionError.errorKey === INVALID_COOKIE_STATE_ERROR_CODE &&
          sessionError.errorCode === INVALID_COOKIE_STATE_ERROR_KEY))
    ) {
      const payload = {
        ...payloadBase,
        skipRedirect: true,
      }

      if (!router.pathname.includes(SIGNIN)) {
        setHasError(true)
        dispatch(resetSessionError())
        dispatch(logout(payload))

        router.push({
          pathname: `/${SIGNIN}`,
          query: { sessionExpired: 'true' },
        })
      }
    }
  }, [sessionError, error])

  useEffect(() => {
    if (hasError && !loginStatus && !isResumingOrder) {
      setHasError(false)

      if (!router.pathname.includes(SIGNIN)) {
        router.push({
          pathname: `/${SIGNIN}`,
          query: { sessionExpired: 'true' },
        })
      }
    }
  }, [hasError, loginStatus])

  Log.info('StoreAppContainer')

  useEffect(() => {
    const cancels: Canceler[] = []

    const payloadBase = {
      widget: widgetName,
      storeId: mySite.storeID,
      langId: mySite?.langId || mySite?.defaultLanguageID,
      cancelToken: new CancelToken(c => {
        cancels.push(c)
      }),
    }

    if (controlUrlCountry) {
      if (mySite) {
        Log.info('INIT STATE FROM STORAGE SITE')
        dispatch(initUserFromStorage(payloadBase))
        storageSessionHandler.triggerUserStorageListener(() => dispatch(updateUserFromStorage(payloadBase)))
      }
    }
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [mySite, dispatch, i18n, widgetName, CancelToken, country])

  Log.info('App routing setup in StoreAppContainer, selected country: ', country)

  return <BaseLayout>{children}</BaseLayout>
}
